<template>
<div class="footer font-small bottom bg-dark pt-2 mt-2 pb-2">
    <div class="container container-central">
        <div class="row">
            <div class="col-12 col-sm-6 text-center">
                Age of Empires II © Microsoft Corporation. ageostatistics.com was created under Microsoft's 
                <a href = "https://www.xbox.com/en-us/developers/rules">Game Content Usage Rules</a>
                using assets from Age of Empires II, and it is not endorsed by or 
                affiliated with Microsoft in any way.
            </div>
            <div class="col-12 col-sm-6 text-center">
                <div class="row">
                    <div class="col d-flex justify-content-center">
                        <a class = "badges bi bi-reddit" href="https://www.reddit.com/message/compose/?to=coolio9876"></a>
                        <a class = "badges bi bi-discord" href="https://discordapp.com/users/Coolio9876#3863"></a>
                        <a class = "badges bi bi-github" href="https://github.com/gowerc/age-of-statistics"></a>
                    </div>
                </div>
                <hr style="margin-top: 5px; margin-bottom:5px" />
                <div class="row">
                    <p style="margin-bottom: 0px">© 2021 ageofstatistics.com</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
export default {
    
}
</script>

<style scoped>
    
.footer a {
    color: rgb(236, 236, 236);
}

.navbar {
    
}

.footer {
    box-shadow: 0px 0px 5px 5px #212529;
    /*background: linear-gradient(180deg, #FFF, #212529 6px );*/
    color: rgb(187, 187, 187);
    font-size: 11px;
}

.badges {
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

</style>